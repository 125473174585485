import payload_plugin_dHWDzdkIDW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_qlUoNfI0TC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2l5THdObtK from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WP6BxqlfRo from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9NuiMgXl0S from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_5fhploekupobco4q2nsqhowxeu/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_8Alz5LE3Eo from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_axYMjRJCU7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NcgaCPdpJZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fRF3KopMRq from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_jCgFNctjn8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_xvltqvrgyelliyiv2yf3jajpi4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_kxCoOEyuOw from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.50.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+api@1._s7nwq5o4jme7ykyzhlokyqigyy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import _01_plugin_qxZ8ztTzCF from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_terser@5.37.0_yaml@2.7.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _02_meta_lhtUYdNOks from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_terser@5.37.0_yaml@2.7.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/02.meta.js";
import _03_define_t4SoSLbg2s from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_terser@5.37.0_yaml@2.7.0_/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_qlUoNfI0TC,
  unhead_2l5THdObtK,
  router_WP6BxqlfRo,
  _0_siteConfig_9NuiMgXl0S,
  payload_client_8Alz5LE3Eo,
  navigation_repaint_client_axYMjRJCU7,
  check_outdated_build_client_NcgaCPdpJZ,
  chunk_reload_client_fRF3KopMRq,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jCgFNctjn8,
  sentry_client_kxCoOEyuOw,
  sentry_client_config_o34nk2sJbg,
  _01_plugin_qxZ8ztTzCF,
  _02_meta_lhtUYdNOks,
  _03_define_t4SoSLbg2s
]