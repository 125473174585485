<template>
  <div>
    <footer
      class="bg-white py-14 dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.03] dark:to-transparent lg:py-[100px]"
    >
      <div class="container">
        <div class="grid gap-y-10 gap-x-4 sm:grid-cols-2 lg:grid-cols-4">
          <div class="relative">
            <NuxtImg
              loading="lazy" src="/assets/images/logo-footer.svg" alt="plurk" class="h-9"
            >
              <NuxtImg
                loading="lazy"
                src="/assets/images/footer-shape.png" alt="footer-shape"
                class="absolute bottom-0 right-0 sm:left-0"
              >
                <NuxtImg
                  loading="lazy"
                  src="/assets/images/footer-shape-dark.png" alt="footer-shape-dark"
                  class="absolute bottom-0 right-0 hidden dark:block sm:left-0"
                />
              </nuxtimg>
            </nuxtimg>
          </div>
          <div>
            <ul class="flex flex-col gap-3 font-bold">
              <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
                {{ $t('footer.menu') }}
              </li>
              <li>
                <a href="/" class="inline-block transition hover:scale-110 hover:text-secondary">
                  {{ $t('navigation.home') }}
                </a>
              </li>
              <li>
                <a
                  href="#offers"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >{{ $t('navigation.services') }}
                </a>
              </li>
              <li>
                <a href="#team" class="inline-block transition hover:scale-110 hover:text-secondary">
                  {{ $t('navigation.about') }}
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  {{ $t('navigation.contact') }}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul class="flex flex-col gap-3 font-bold">
              <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
                {{ $t('navigation.services') }}
              </li>
              <li>
                <a
                  href="#offers"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  {{ $t('footer.solutionDesign') }}
                </a>
              </li>
              <li>
                <a
                  href="#offers"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  {{ $t('footer.webDevelopment') }}
                </a>
              </li>
              <li>
                <a
                  href="#offers"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  {{ $t('footer.mobileDevelopment') }}
                </a>
              </li>
              <li>
                <a
                  href="#offers"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  {{ $t('footer.web3Development') }}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul class="flex flex-col gap-3 font-bold">
              <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
                {{ $t('footer.information') }}
              </li>
              <li>
                Develit.io s.r.o.
                <br>
                {{ $t('footer.ICO') }}: 19200714

                <!-- <br> {{ $t('footer.DIC') }}: CZ9411103053 -->
              </li>
              <li>
                Kurzova 2222/16
                <br>
                Praha 5 155 00
                <br>
                {{ $t('footer.state') }}
              </li>
              <li>
                <a
                  href="tel:+420 606 954 642"
                  class="inline-block transition hover:scale-110 hover:text-secondary"
                >
                  +420 606 954 642</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <div class="py-5 dark:border-t-2 dark:border-white/5">
      <div class="container">
        <div class="items-center justify-between text-center font-bold dark:text-white md:flex">
          <div>
            {{ $t('footer.copyright') }}© 2024 Develit.io IT Solutions
          </div>
          <div>
            {{ $t('footer.rightsReserved') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
