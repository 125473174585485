<script setup lang="ts">
const { $t } = useNuxtApp()

useHead({
  title: 'Develit.io',
  titleTemplate: 'Develit.io',
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { hid: 'description', name: 'description', content: $t('app.description') as string },
    { name: 'format-detection', content: 'telephone=no' },
    {
      name: 'description',
      content: $t('app.description') as string,
    },
    {
      property: 'og:image',
      content: 'https://develit.io/develit-icon.png',
    },
  ],
  script: [
    {
      'src': 'https://plausible.io/js/script.js',
      'defer': true,
      'data-domain': 'develit.io',
    },
  ],
  link: [
    { rel: 'icon', type: 'image/svg', href: '/favicon.svg' },
  ],
})
</script>

<template>
  <div class="overflow-x-hidden">
    <Info />

    <Offers />

    <Services />

    <Team />

    <Partners />

    <Contact />
  </div>
</template>
