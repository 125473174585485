<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import AOS from 'aos'

import 'aos/dist/aos.css'

// You can also use <link> for styles
import appSetting from '@/app-setting'

import { useAppStore } from '@/stores/index'

const store = useAppStore()

const showTopButton = ref(false)
onMounted(() => {
  // set default settings and animation initalization
  AOS.init({
    once: true,
  })
  appSetting.init()

  window.onscroll = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50)
      showTopButton.value = true
    else
      showTopButton.value = false
  }

  store.toggleMainLoader(false)
})

function scrollToTop () {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}
</script>

<template>
  <div class="bg-white font-mulish text-base text-gray antialiased dark:bg-[#101926]">
    <!-- screen loader -->
    <div v-show="store.isShowMainLoader" class="screen_loader fixed inset-0 z-[60] grid place-content-center bg-[#fafafa] dark:bg-[#060818]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="50" cy="50" r="0" fill="none" stroke="#47bdff" stroke-width="4">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            values="0;16"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"
          />
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"
          />
        </circle>
        <circle cx="50" cy="50" r="0" fill="none" stroke="#b476e5" stroke-width="4">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            values="0;16"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-0.5s"
          />
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="-0.5s"
          />
        </circle>
      </svg>
    </div>

    <div>
      <layout-header :class="{ 'sticky-header': showTopButton }" />

      <div class="-mt-[82px] bg-gradient-to-r from-[#FCF1F4] to-[#EDFBF9] dark:bg-none lg:-mt-[106px]">
        <NuxtPage />

        <layout-footer />
      </div>
    </div>

    <!-- Return to Top -->
    <button v-if="showTopButton" type="button" class="fixed bottom-5 z-10 animate-bounce ltr:right-5 rtl:left-5" :title="$t('navigation.up')" @click="scrollToTop()">
      <div
        class="group flex h-14 w-14 items-center justify-center rounded-full border border-black/20 bg-black text-white transition duration-500 hover:bg-secondary dark:bg-primary dark:hover:bg-secondary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-6 w-6 transition group-hover:text-black"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
      </div>
    </button>
  </div>
</template>
